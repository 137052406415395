import { serviceHandler } from '../utils/serviceHandler';
import config from '../config';

const {
  addUserRoles, listUserRoles, editUserRole, deleteUserRole, addPolicies,
  listPolicies, editPolicy, deletePolicy, addFleet, listFleets, editFleet, deleteFleet,
  addUser, listUsers, editUser, deleteUser, authenticateUser, checkUserEmailValid,
  toggleUserStatus, getAllPermissions, uploadUserManual, getUserManual
} = config.routes;

export const addUserRolePOST = (payload) => {
  return serviceHandler({
    url: addUserRoles,
    methodType: 'post',
    payload
  });
};

export const listUserRolesPOST = (payload) => {
  return serviceHandler({
    url: listUserRoles,
    methodType: 'post',
    payload
  });
};

export const editUserRolePUT = (payload) => {
  return serviceHandler({
    url: editUserRole,
    methodType: 'put',
    payload
  });
};

export const deleteUserRoleDELETE = ({ id, ...payload }) => {
  return serviceHandler({
    url: `${deleteUserRole}?id=${id}`,
    methodType: 'delete',
    payload
  });
};

export const addPoliciesPOST = (payload) => {
  return serviceHandler({
    url: addPolicies,
    methodType: 'post',
    payload
  });
};

export const listPoliciesPOST = (payload) => {
  return serviceHandler({
    url: listPolicies,
    methodType: 'post',
    payload
  });
};

export const editPolicyPUT = (payload) => {
  return serviceHandler({
    url: editPolicy,
    methodType: 'put',
    payload
  });
};

export const deletePolicyDELETE = (payload) => {
  return serviceHandler({
    url: deletePolicy,
    methodType: 'delete',
    payload: { params: payload }
  });
};

export const addFleetPOST = (payload) => {
  return serviceHandler({
    url: addFleet,
    methodType: 'post',
    payload
  });
};

export const listFleetsPOST = (payload) => {
  return serviceHandler({
    url: listFleets,
    methodType: 'post',
    payload
  });
};

export const editFleetPUT = (payload) => {
  return serviceHandler({
    url: editFleet,
    methodType: 'put',
    payload
  });
};

export const deleteFleetDELETE = (payload) => {
  return serviceHandler({
    url: deleteFleet,
    methodType: 'delete',
    payload: { params: payload }
  });
};

export const addUserPOST = (payload) => {
  return serviceHandler({
    url: addUser,
    methodType: 'post',
    payload
  });
};

export const listUsersPOST = (payload) => {
  return serviceHandler({
    url: listUsers,
    methodType: 'post',
    payload
  });
};

export const editUserPUT = (payload) => {
  return serviceHandler({
    url: editUser,
    methodType: 'put',
    payload
  });
};

export const deleteUserDELETE = (payload) => {
  return serviceHandler({
    url: deleteUser,
    methodType: 'delete',
    payload: { params: payload }
  });
};

export const authenticateUserPOST = (payload) => {
  return serviceHandler({
    url: authenticateUser,
    methodType: 'post',
    payload
  });
};

export const checkUserEmailValidGET = (payload) => {
  return serviceHandler({
    url: checkUserEmailValid,
    methodType: 'get',
    payload: { params: payload }
  });
};

export const toggleUserStatusPUT = (payload) => {
  return serviceHandler({
    url: toggleUserStatus,
    methodType: 'put',
    payload
  });
};

export const getAllPermissionsGET = (payload) => {
  return serviceHandler({
    url: getAllPermissions,
    methodType: 'get',
    payload: { params: payload }
  });
};

export const uploadUserManualPOST = (payload) => {
  return serviceHandler({
    url: uploadUserManual,
    methodType: 'post',
    payload
  });
};

export const getUserManualPOST = (payload) => {
  return serviceHandler({
    url: getUserManual,
    methodType: 'post',
    payload
  });
};
