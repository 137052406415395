/* istanbul ignore file */
import React, { Component } from 'react';
import AuthService from '../auth/authService';

export const AuthContext = React.createContext({});

export const AuthConsumer = AuthContext.Consumer;

export const authService = new AuthService();

export class AuthProvider extends Component {
  authService;

  constructor(props) {
    super(props);
    this.authService = authService;
  }

  render() {
    const { children } = this.props;
    return (
      <AuthContext.Provider value={this.authService}>
        {children}
      </AuthContext.Provider>
    );
  }
}
