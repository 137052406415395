/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { Icon } from '@synergy-marine-group/synergy-ui';
import { AuthContext } from '../components/providers/AuthProvider';
import Layout from '../components/layout';
import Loader from '../components/_common/loader';
import { authenticateUserPOST } from '../services/userServices';
import Button from '../components/_common/button';

const PrivateRoute = ({ component, settings, ...rest }) => {
  const { permissions = [] } = settings;

  const authService = useContext(AuthContext);
  const history = useHistory();
  const [user, setUser] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);

  useEffect(() => {
    async function setAuthenticatedUser() {
      let authenticatedUser = await authService.signinSilent();
      if ((!authenticatedUser || authenticatedUser.expired)) {
        authService.signinRedirect();
        return;
      }
      if (!authenticatedUser) {
        authenticatedUser = {
          access_token: localStorage.getItem('access_token'),
          id_token: localStorage.getItem('id_token')
        };
      }
      if (authenticatedUser?.access_token) {
        authenticatedUser.profile = jwtDecode(authenticatedUser.access_token);
      }
      let userDetails = authService.getUserDetails();

      if (!userDetails?.token) {
        try {
          const res = await authenticateUserPOST();
          userDetails = jwtDecode(res?.data?.token?.result);
          userDetails.token = res?.data?.token?.result;
          authService.setUserDetails(userDetails);
        } catch (err) {
          if (err?.response?.status === 401) {
            history.push('/unauthorized');
          } else if (err?.response?.status === 403) {
            history.push('/forbidden');
          } else {
            setIsLoginError(true);
          }
        }
      }
      setUser(authenticatedUser);
    }
    if (authService) setAuthenticatedUser();
  }, [authService]);

  const renderFn = (Component) => () => {
    const { isAuthenticated, getUserDetails } = authService;
    const userPermissions = getUserDetails()?.Permissions || [];
    if (isLoginError) {
      return (
        <div className="flex flex-col justify-center items-center h-screen" data-testid="forbidden-indicator">
          <div>
            <div className="text-center mb-3">
              <Icon size="large" className="text-red-500">error</Icon>
            </div>
            <div className="font-montserrat font-semibold text-xl text-gray-500 mb-1 text-center">
              Oops... Something went wrong.
            </div>
            <div className="font-muli font-normal text-base text-gray-500 text-center mb-6">
              An issue occured while we were trying to log you in.
            </div>
            <div className="text-center">
              <Button rounded onClick={() => authService.logout()} size="medium">Logout</Button>
            </div>
          </div>
        </div>
      );
    }
    if (isAuthenticated && user && permissions?.length
      && !permissions.some((permission) => userPermissions.includes(permission))) {
      return <Redirect path="/vessel-locator" />;
    }
    if (isAuthenticated && user) {
      return (
        <Layout user={user} settings={settings}>
          <Component />
        </Layout>
      );
    }
    return (
      <Loader label="Validating credentials" />
    );
  };

  return <Route {...rest} render={renderFn(component)} user={user} />;
};

export default PrivateRoute;
