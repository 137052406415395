import React from 'react';
import styles from './index.module.scss';

const Messagebox = (props) => {
  const { message } = props;
  return (
    <div className={styles.message}>
      <span className="font-montserrat text-sm items-center leading-none">{message}</span>
    </div>
  );
};

export default Messagebox;
