import axios from 'axios';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { apiurl } from '../config/apiUrl';
import showAlert from './alert';
import { authService } from '../components/providers/AuthProvider';
// import history from './history';

let mutex = null;

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.interceptors.request.use(
  async (config) => {
    const auth = { headers: {} };
    if (config.url === apiurl.authenticateUser) {
      auth.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}` || null;
    } else {
      const token = localStorage.getItem('auth_token');
      if (token) {
        const decoded = jwtDecode(token);
        if (moment().isAfter(moment.unix(decoded.exp)) && !mutex) {
          mutex = new Promise((resolve, reject) => {
            axios.post(apiurl.authenticateUser)
              .then((res) => {
                const userDetails = jwtDecode(res?.data?.token?.result);
                userDetails.token = res?.data?.token?.result;
                authService.setUserDetails(userDetails);
              })
              .catch(() => {
                window.location.replace('/unauthorized');
                reject();
              })
              .finally(() => resolve());
          });
          await mutex;
          mutex = null;
        } else {
          await mutex;
        }
      }
      auth.headers.Authorization = `Bearer ${localStorage.getItem('auth_token')}` || null;
    }
    return { ...config, ...auth };
  },
  (error) => {
    return error;
  }
);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.config && error.response.config.method !== 'get') {
      switch (error.response.status) {
        case 500:
          showAlert('Oops! Something went wrong. Our team has been notified, and we\'re working to fix it. Please try again later.', 'error');
          break;
        case 402:
          showAlert(error.response.data);
          break;
        case 401:
          showAlert('You are not authorized to access this resource.', 'error');
          // localStorage.clear();
          // history.push('/unauthorized');
          break;
        case 403:
          showAlert('You are not authorized to access this resource.', 'error');
          if (error?.response?.config?.url !== apiurl.authenticateUser) window.location.reload();
          break;
        case 404:
          showAlert('Something went wrong.', 'error');
          break;
        case 400:
          showAlert('Something went wrong.', 'error');
          break;
        case 422:
          if (error?.response?.request?.responseType === 'blob') {
            const response = await error?.response?.data.text();
            const parsed = JSON.parse(response);
            if (parsed?.Message) {
              showAlert(parsed?.Message || 'Something went wrong', 'error');
            } else {
              showAlert(parsed?.errors?.message || parsed?.Message || 'Something went wrong', 'warning');
            }
          } else {
            showAlert(error?.response?.data?.errors?.message || error?.response?.data?.Message || 'Something went wrong', 'error');
          }
          break;
        default:
          break;
      }
    } else if (
      error?.response?.config?.method === 'get'
      && error?.response?.config?.url === apiurl.vesselDetails
      && error?.response?.status === 403
    ) {
      window.location.replace('/unauthorized');
    }

    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  all: axios.all,
  spread: axios.spread
};
