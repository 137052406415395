import React from 'react';
import { ClipLoader } from 'react-spinners';

const Loader = (props) => {
  return (
    <div className="w-full flex justify-center mt-4 mb-4">
      <ClipLoader {...props} loading color="#333869" />
    </div>
  );
};

export default Loader;
