import React, { useEffect, useMemo, useState } from 'react';
import Navigation from '../_common/navigation';
import styles from './index.module.scss';
import Messagebox from '../_common/messagebox';
import { getMessageGET } from '../../services/vesselServices';
import { VESSEL_TABLE_COLUMS } from '../../config/constants';

export const LayoutState = React.createContext();

const getAllLabels = () => {
  const labels = [];
  VESSEL_TABLE_COLUMS.forEach((setting) => {
    labels.push(setting.label);
    setting.Children.forEach((child) => {
      labels.push(child.label);
    });
  });
  return labels;
};

const Layout = (props) => {
  const { user, children, settings } = props;
  const [isPinned, setIsPinned] = useState(localStorage.getItem('isPinned') === 'true');
  const [message, setMessage] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [checkedLabels, setCheckedLabels] = useState(getAllLabels());

  const userData = useMemo(() => {
    let firstName = '';
    let lastName = '';
    if (user?.profile?.given_name) {
      firstName = user.profile.given_name;
    }
    if (user?.profile?.family_name) {
      lastName = user.profile.family_name;
    }
    if (user?.profile?.name) {
      const splitName = user.profile.name.replace(/[^a-zA-Z0-9 ]/g, '').split(' ');
      [firstName] = splitName;
      lastName = splitName.length > 1 ? splitName[1] : '';
    }
    return { firstName, lastName };
  }, [user]);

  useEffect(() => {
    localStorage.setItem('isPinned', isPinned);
  }, [isPinned]);

  useEffect(() => {
    getMessageGET()
      .then((res) => {
        if (res?.data.isActive === 1) {
          setIsActive(true);
          setMessage(res.data.message);
        } else {
          setIsActive(false);
        }
      });
  }, []);

  useEffect(() => {
    sessionStorage.setItem('checkedLabels', JSON.stringify(checkedLabels));
  }, [checkedLabels]);

  return (
    <div className={`${styles.page_wrapper} ${settings?.hasStickyContent ? styles.withStickyContent : ''} ${settings?.isFullScreen ? styles.fullscreen : isPinned && styles.pinned}`}>
      {isActive && (
        <Messagebox
          message={message}
          isActive={isActive}
        />
      )}
      <div className="">
        <LayoutState.Provider
          value={{
            isPinned,
            isActive,
            checkedLabels,
            setCheckedLabels
          }}
        >
          {children}
          <div className={`${styles.nav} ${settings?.isFullScreen ? styles.fullscreen : ''} ${isActive ? styles.isactive : ''}`}>
            <Navigation
              userdata={userData}
              isPinned={settings?.isFullScreen || isPinned}
              isFullScreen={settings?.isFullScreen}
              onClickPin={() => !settings?.isFullScreen && setIsPinned(true)}
              onClickUnpin={() => !settings?.isFullScreen && setIsPinned(false)}
            />
          </div>
        </LayoutState.Provider>
      </div>
    </div>
  );
};

export default Layout;
