import { UserManager, WebStorageStateStore } from 'oidc-client';
import jwtDecode from 'jwt-decode';
import IDENTITY_CONFIG from './authConstants';
import config from '../../config';

const { redirectUrl } = config;

export default class AuthService {
  UserManager;

  accessToken;

  userDetails;

  constructor() {
    this.UserManager = new UserManager({
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.localStorage })
    });

    this.UserManager.events.addUserSignedOut(async () => {
      try {
        await this.UserManager.signinSilent();
      } catch (error) {
        // localStorage.clear();
        // window.location.replace('/post-logout');
      }
    });
    this.UserManager.events.addUserLoaded(async (user) => {
      this.accessToken = user.access_token;

      localStorage.setItem('access_token', user.access_token);
      localStorage.setItem('id_token', user.id_token);
      this.setUserInfo({
        accessToken: this.accessToken,
        idToken: user.id_token
      });
      if (window.location.href.indexOf('authentication/callback') !== -1) {
        this.navigateToScreen();
      }
    });

    this.UserManager.events.addAccessTokenExpiring(() => {
      this.UserManager.signinSilent({
        scope: IDENTITY_CONFIG.scope,
        response_type: IDENTITY_CONFIG.response_type
      })
        .then((user) => {
          localStorage.setItem('access_token', user.access_token);
        })
        .catch(() => {
          // this.userManager.getUser().then(() => {
          // });
        });
    });

    this.UserManager.events.addSilentRenewError(() => {
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('id_token');
      this.signinSilent();
      this.UserManager.clearStaleState().then(() => {
      });
    });
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback().then(() => { }).catch(() => {
      window.location.replace(redirectUrl);
    });
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  setUserInfo = (authResult) => {
    const data = jwtDecode(this.accessToken);
    this.setSessionInfo(authResult);
    this.setUser(data);
  };

  signinRedirect = () => {
    localStorage.setItem('redirectUri', window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  setUser = (data) => {
    localStorage.setItem('userId', data.sub);
  };

  navigateToScreen = () => {
    const redirectUri = localStorage.getItem('redirectUri') || '/';
    window.location.replace(redirectUri);
  };

  setSessionInfo = (authResult) => {
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
  };

  isAuthenticated = () => {
    const accessToken = localStorage.getItem('access_token');
    return accessToken;
  };

  signinSilent = async () => {
    try {
      let user = await this.UserManager.getUser();
      if (!user) {
        user = await this.UserManager.signinRedirect();
      }
      return user;
    } catch (error) {
      return false;
    }
  };

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  getUserDetails = () => {
    return this.userDetails;
  };

  setUserDetails = (userDetails) => {
    this.userDetails = userDetails;
    if (!this.userDetails.Permissions) this.userDetails.Permissions = [];
    localStorage.setItem('auth_token', userDetails.token);
  };

  logout = () => {
    localStorage.setItem('logoutActionBy', 'logout');
    localStorage.clear();
    sessionStorage.clear();
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token')
    });
    // this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback()
      .then(() => {
        const logoutActionBy = localStorage.getItem('logoutActionBy');

        this.UserManager.clearStaleState();
        localStorage.clear();

        if (logoutActionBy && logoutActionBy === 'unauthorized') {
          window.location.replace('/unauthorized');
        } else {
          window.location.replace('/post-logout');
        }
      })
      .catch(() => {
      });
  };
}
