/* eslint-disable max-len */
const authConstants = {
  authority: process.env.REACT_APP_AUTH_URL, // (string): The URL of the OIDC provider.
  client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID, // (string): Your client application's identifier as registered with the OIDC provider.
  redirect_uri: process.env.REACT_APP_REDIRECT_URL, // The URI of your client application to receive a response from the OIDC provider.
  automaticSilentRenew: true, // (boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL, // (string): The URL for the page containing the code handling the silent renew.
  post_logout_redirect_uri: process.env.REACT_APP_LOGOFF_REDIRECT_URL, // (string): The OIDC post-logout redirect URI.
  response_type: 'code', // (string, default: 'id_token'): The type of response desired from the OIDC provider.
  triggerAuthFlow: true,
  includeIdTokenInSilentRenew: false,
  grantTypes: 'authorization_code',
  scope: process.env.REACT_APP_IDENTITY_SCOPE
};
export default authConstants;
