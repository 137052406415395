import React from 'react';
import { Icon } from '@synergy-marine-group/synergy-ui';
import { useHistory } from 'react-router-dom';
import Button from '../button';

const NotFound = () => {
  const history = useHistory();

  return (
    <div className="flex flex-col justify-center items-center h-screen" data-testid="unauthorized-indicator">
      <div>
        <div className="text-center mb-3">
          <Icon size="large" className="text-gray-500">cloud_off</Icon>
        </div>
        <div className="font-montserrat font-semibold text-xl text-gray-500 text-center mb-2">
          Page not found
        </div>
        <div className="font-muli font-normal text-base text-gray-500 text-center mb-4">
          Try going back to the previous page, or go to vessel locator.
        </div>
        <div className="text-center">
          <Button rounded onClick={() => history.push('/vessel-locator')} size="medium">
            Go to home
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
