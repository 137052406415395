import React, { useEffect, useMemo, useRef, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Icon } from '@synergy-marine-group/synergy-ui';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { ReactComponent as SynergyLogo } from '../../../images/icons/synergy_logo.svg';
import styles from './index.module.scss';
import { useResizeObserver } from '../../../utils/hooks';

const Nav = (props) => {
  const {
    menuitems: menuItems = [], isPinned = false, onClickPin, onClickUnpin,
    isFullScreen, userdata: userData
  } = props;

  const [open, setOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const ref = useRef();
  const { width } = useResizeObserver(ref);

  const isWrapped = useMemo(() => {
    if (ref.current && isPinned) {
      const outOfBoundsChildren = Array.from(ref.current.children)
        .filter((item) => {
          return item.offsetLeft > ref.current.children[0].offsetLeft;
        });

      if (outOfBoundsChildren?.length) return true;
      return false;
    }
    return false;
  }, [ref.current, width, isPinned]);

  const onPinClick = () => {
    if (isPinned) onClickUnpin();
    else onClickPin();
  };

  const logout = () => {
    history.push('/logout');
  };

  useEffect(() => {
    if (!isPinned) setShowMore(false);
  }, [isPinned]);

  return (
    <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
      <div className={`${styles.nav} ${open || isPinned ? styles.open : ''} ${isPinned ? styles.pinned : ''} ${showMore ? styles.showMore : ''} ${isFullScreen ? styles.fullscreen : ''}`}>
        <div className={styles.synergy_icon}>
          <SynergyLogo
            width="1.929rem"
            height="2.286rem"
            className="cursor-pointer"
            onClick={() => history.push('/')}
          />
          {open || isPinned
            ? (
              <Icon
                className={styles.pin_icon}
                variant={isPinned ? 'filled' : 'outlined'}
                onClick={onPinClick}
              >
                push_pin
              </Icon>
            ) : null}
        </div>
        <div className={styles.expand_icon}>
          <img
            alt=""
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAAAQCAYAAACm53kpAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAF2SURBVFhHY8wrnPKfYQQDJig9YsFoAEBpugN2dlaGxDh3Bi9Pc6jIwIABCQCQ56PCnRj4BbgYdHUUBjQQBiQAYJ5ftmI/w7ZtJwc0EJjNLb0aoGy6AW5uToaDhy4xvHr1geHV6w8ML19/ZPj75y/Ds+dvoSroB0arQSg9YgETIwPDAyibbsDGWptBTEwAymMAs01N1KA8+gGg3y8w/f//fyOUTzegqiLDEBXhCPa4ro4ikO3EwMvLBZWlH/j3n+ECs6mF1w9GRsYEqBhdwPUbjxnUVGXAsa6pIQfkP2LYtfssVJZ+4DfD70DmUye2PzC38BRkYGS0gIrTHPz9+xceCPfuP2fYtv0UVIae4P/EqRPyV4ALQSaGP6Cq8AKITS/w8+cvhvkLdw6I5yF5H+xnSC0wYULhB6b/vx0Z/v+fCOIPb/B/IiPQryA/g3jAwEAFBQX9Cv8YWBqAWUIfyDWAiA558AAYuRv/MTBsmDIh9wBUDAgYGABsTnPbyvLXSAAAAABJRU5ErkJggg=="
            onClick={() => setOpen(true)}
          />
        </div>
        <div className={styles.menu_wrapper}>
          <div className={styles.menu_list} ref={ref}>
            {menuItems.map((item) => (
              <Link to={item.url} key={item.url}>
                <div
                  className={`${styles.menu_item} ${item.url === location.pathname ? styles.active : ''}`}
                >
                  <Icon className={styles.menu_icon}>{item.iconName}</Icon>
                  <div className={styles.menu_title}>{item.title}</div>
                </div>
              </Link>
            ))}
          </div>
          <div className="flex flex-col items-center w-full px-2">
            {isPinned && isWrapped
              ? (
                <div
                  className={`${styles.menu_item} ${styles.more_icon}`}
                  onClick={() => setShowMore((prev) => !prev)}
                >
                  <Icon className={styles.menu_icon}>more_horiz</Icon>
                  <div className={styles.menu_title}>
                    {showMore ? 'Hide' : 'More'}
                  </div>
                </div>
              ) : null}
            <div className={styles.user_section}>
              <OutsideClickHandler onOutsideClick={() => setUserMenuOpen(false)}>
                <div
                  className={`${styles.user_avatar} ${userMenuOpen ? styles.selected : ''}`}
                  onClick={() => setUserMenuOpen((prev) => !prev)}
                >
                  <span>
                    {userData?.firstName?.length ? userData?.firstName[0] : ''}
                    {userData?.lastName?.length ? userData?.lastName[0] : ''}
                  </span>
                  {userMenuOpen
                    ? (
                      <div
                        className={styles.logout_menu}
                        onClick={logout}
                      >
                        Logout
                      </div>
                    ) : null}
                </div>
              </OutsideClickHandler>
            </div>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Nav;
