import React from 'react';
import { Button as SynButton, Icon } from '@synergy-marine-group/synergy-ui';

const Button = (props) => {
  const { icon, children, iconSize = 'small', ...rest } = props;
  return (
    <SynButton
      {...rest}
    >
      <div className="flex items-center">
        {icon
          ? <Icon className="mr-2" color="gray" size={iconSize}>{icon}</Icon>
          : null}
        <span className="font-montserrat font-semibold text-gray-500">
          {children}
        </span>
      </div>
    </SynButton>
  );
};

export default Button;
