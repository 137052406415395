import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import config from './config';
import PrivateRoute from './utils/privateRoute';
import routeList from './router';
import Loader from './components/_common/loader';
import history from './utils/history';
import NotFound from './components/_common/notFound';

function App() {
  return (
    <Router basename="/" config={config} history={history}>
      <React.Suspense
        fallback={<Loader />}
      >
        <Switch>
          {routeList.secureRoutes.map((route) => {
            const { key, path, component, ...rest } = route;
            return (
              <PrivateRoute
                exact
                key={key}
                path={path}
                component={component}
                settings={rest}
              />
            );
          })}
          <Redirect from="/" to="/vessel-locator" exact />
          {routeList.publicRoutes.map((route) => (
            <Route exact key={route.key} path={route.path} component={route.component} />
          ))}
          <Route path="*" component={NotFound} />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;
