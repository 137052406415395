import { apiurl } from './apiUrl';
import { LAYER_KEYS } from './constants';

const all = {
  routes: apiurl,
  redirectUrl: '/vessel-locator',
  domain_app: process.env.REACT_APP_DOMAIN_APP,
  workspace_id: process.env.REACT_APP_WORKSPACE_ID,
  gmap_key: process.env.REACT_APP_GMAP_KEY
};

const env = {
  develop: {
    powerbi_report_ids: {
      particulars: '3765404b-b235-4f49-98a2-a6c844cb1c1f',
      vessel_report: '0b36c84e-0ee0-4dbc-82eb-cffb7f17a82f',
      defect_list: {
        'Incident - Accident & Damage/Injury': '2c71c5fe-640d-4776-a277-123dde7d49d9',
        'Hull & Machinery Defect': '5a761ea6-ba8a-4fb5-a3c0-815fd7dfd91d',
        'Incident - Near miss': '531e1163-a713-45e0-8128-3dc1647d20f8',
        'Inspection - Free flow reporting': '050c58a0-4458-4560-89d4-67a404af9f7c',
        COC: '050c58a0-4458-4560-89d4-67a404af9f7c',
        shipnet: 'fc6f9b49-9385-4833-a017-f39a16b99d2a'
      },
      bunkering: {
        report: '441ed7df-962e-4979-a74b-10e5d0e3333d',
        survey: 'f44ddaee-bec1-493c-88a8-9430a4226597'
      }
    },
    layersData: {
      [LAYER_KEYS.ECA_LAYER]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/ECA.kml',
      [LAYER_KEYS.LOADLINES_LAYER]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/Loadlines.kml',
      [LAYER_KEYS.HIGH_RISK_AREAS]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/Higriskzone.kml',
      [LAYER_KEYS.SENSITIVE_AREAS]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/ParticularySensitiveSeaAreas.kml',
      [LAYER_KEYS.WORLD_TIMEZONES]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/WorldTimeZonesPlaces.kml',
      [LAYER_KEYS.JWC_LAYER]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/JWC.kml',
      [LAYER_KEYS.EU_WATERS_LAYER]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/EUwaters.kml',
      [LAYER_KEYS.PIRACY_RISK_LAYER]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/Piracyrisks.kml'
    }
  },
  qa: {
    powerbi_report_ids: {
      particulars: '43685e17-081b-4542-bd4c-73401ae1296c',
      vessel_report: '474d8e00-145a-4c4f-bb07-5e6444139b80',
      defect_list: {
        'Incident - Accident & Damage/Injury': 'b64d5118-313e-43e1-b3bd-9a50309ca644',
        'Hull & Machinery Defect': 'e329109d-11b0-4707-9b01-bbc14f374d6b',
        'Incident - Near miss': '490db006-8165-4084-8eff-0f8c1512a060',
        'Inspection - Free flow reporting': '03921811-5f2d-4d17-bace-8f8bc7ae4be6',
        COC: '03921811-5f2d-4d17-bace-8f8bc7ae4be6',
        shipnet: 'fc6f9b49-9385-4833-a017-f39a16b99d2a'
      },
      bunkering: {
        report: '49c74822-9bca-4dbc-9cde-1d3d086b4328',
        survey: '71e6fe31-3885-4933-aad0-a9eb2346803d'
      }
    },
    layersData: {
      [LAYER_KEYS.ECA_LAYER]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/ECA.kml',
      [LAYER_KEYS.LOADLINES_LAYER]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/Loadlines.kml',
      [LAYER_KEYS.HIGH_RISK_AREAS]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/Higriskzone.kml',
      [LAYER_KEYS.SENSITIVE_AREAS]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/ParticularySensitiveSeaAreas.kml',
      [LAYER_KEYS.WORLD_TIMEZONES]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/WorldTimeZonesPlaces.kml',
      [LAYER_KEYS.JWC_LAYER]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/JWC.kml',
      [LAYER_KEYS.EU_WATERS_LAYER]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/EUwaters.kml',
      [LAYER_KEYS.PIRACY_RISK_LAYER]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/Piracyrisks.kml'
    }
  },
  staging: {
    powerbi_report_ids: {
      particulars: '26470696-b58e-41ef-9bbe-0498c85e68f4',
      vessel_report: '63025ff5-f6ec-4d0d-bef2-1cc954e006c7',
      defect_list: {
        'Incident - Accident & Damage/Injury': '4c16b310-1648-4ce6-abf5-33a67254c2bf',
        'Hull & Machinery Defect': '48867bc5-7767-40a7-95ad-ab814d593823',
        'Incident - Near miss': 'f0c74df1-6ea0-4bda-9ac2-1628c9dd5f84',
        'Inspection - Free flow reporting': '9000765a-3aae-4405-85c0-632b691d454f',
        COC: '9000765a-3aae-4405-85c0-632b691d454f',
        shipnet: 'd7d84753-4520-4344-be69-ca47db6e755b'
      },
      bunkering: {
        report: '5a7d2053-1023-43db-b7a9-beb5c94995f1',
        survey: '3fd539dd-c665-4cc5-b266-facb235ef8c8'
      }
    },
    layersData: {
      [LAYER_KEYS.ECA_LAYER]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/ECA.kml',
      [LAYER_KEYS.LOADLINES_LAYER]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/Loadlines.kml',
      [LAYER_KEYS.HIGH_RISK_AREAS]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/Higriskzone.kml',
      [LAYER_KEYS.SENSITIVE_AREAS]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/ParticularySensitiveSeaAreas.kml',
      [LAYER_KEYS.WORLD_TIMEZONES]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/WorldTimeZonesPlaces.kml',
      [LAYER_KEYS.JWC_LAYER]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/JWC.kml',
      [LAYER_KEYS.EU_WATERS_LAYER]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/EUwaters.kml',
      [LAYER_KEYS.PIRACY_RISK_LAYER]: 'https://crpprojectdev.blob.core.windows.net/kmlfiles/New/Piracyrisks.kml'
    }
  },
  production: {
    powerbi_report_ids: {
      particulars: '7a7b5fe9-3022-40b8-be9e-34821f604b2e',
      vessel_report: 'e1d896f0-45c6-4f04-a7f2-6c5fe742c577',
      defect_list: {
        'Incident - Accident & Damage/Injury': '9a1a5c47-bcb3-42a8-ba6e-9aedebc4ed7c',
        'Hull & Machinery Defect': 'eb50692d-d5aa-487d-9c5c-5384046d6ac2',
        'Incident - Near miss': '27eee36f-a3fa-4ed2-924f-c5986056ffe1',
        'Inspection - Free flow reporting': '9f8bc47b-d3f3-4f1f-9ebd-5cb821937c62',
        COC: '9f8bc47b-d3f3-4f1f-9ebd-5cb821937c62',
        shipnet: 'b235c3eb-0207-47b9-a6f6-a29e28bb9dd8'
      },
      bunkering: {
        report: 'a44e15ef-f3bb-437f-b672-5b3e62d7147c',
        survey: 'c50370f8-502b-427c-8fcc-31896abf6232'
      }
    },
    layersData: {
      [LAYER_KEYS.ECA_LAYER]: 'https://crpprojectprod.blob.core.windows.net/kmlfiles/ECA.kml',
      [LAYER_KEYS.LOADLINES_LAYER]: 'https://crpprojectprod.blob.core.windows.net/kmlfiles/Loadlines.kml',
      [LAYER_KEYS.HIGH_RISK_AREAS]: 'https://crpprojectprod.blob.core.windows.net/kmlfiles/Higriskzone.kml',
      [LAYER_KEYS.SENSITIVE_AREAS]: 'https://crpprojectprod.blob.core.windows.net/kmlfiles/ParticularySensitiveSeaAreas.kml',
      [LAYER_KEYS.WORLD_TIMEZONES]: 'https://crpprojectprod.blob.core.windows.net/kmlfiles/WorldTimeZonesPlaces.kml',
      [LAYER_KEYS.JWC_LAYER]: 'https://crpprojectprod.blob.core.windows.net/kmlfiles/JWC.kml',
      [LAYER_KEYS.EU_WATERS_LAYER]: 'https://crpprojectprod.blob.core.windows.net/kmlfiles/EUwaters.kml',
      [LAYER_KEYS.PIRACY_RISK_LAYER]: 'https://crpprojectprod.blob.core.windows.net/kmlfiles/Piracyrisks.kml'
    }
  }
};

export default {
  ...all,
  ...env[process.env.REACT_APP_NODE_ENV]
};
